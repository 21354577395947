<template>
  <div class="footer">
    <a href="https://www.spiceworks.com/privacy" class="footer-link" target="_blank">Privacy Policy</a>
    <span class="footer-separator">·</span>
    <a href="https://www.ziffdavis.com/terms-of-use" class="footer-link" target="_blank">Terms of Use</a>
    <span class="footer-separator">·</span>
    <a href="https://www.spiceworks.com/privacy/cookies " class="footer-link" target="_blank">Cookie Policy</a>
    <span class="footer-separator">·</span>
    <a href="https://www.spiceworks.com/accessibility-statement " class="footer-link" target="_blank">Accessibility Statement</a>
    <span class="footer-separator">·</span>
    <a href="https://dsar.spiceworks.com" class="footer-link" target="_blank">Do Not Sell My Personal Information</a>
    <span class="footer-separator">·</span>
    <span class="footer-link">© 2006-{{ currentYear }} Spiceworks Inc.</span>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  const currentYear = ref(new Date().getFullYear());
</script>

<style scoped>
.footer {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #F6F7F8;
  color: #54585A;
  font-size: 12px;
  line-height: 18px;
  position: fixed;
  bottom: 0;
}

.footer-link {
  margin: 0 5px;
  color: #54585A;
}
</style>
